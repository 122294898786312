import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
// import { initializedFirebaseApp } from './config/firebaseConfig.js';
import { getMessaging, onMessage } from "firebase/messaging";

import { fetchIpAddress } from "./controllers/API"

import LoginPage from "./components/LoginPage/LoginPage.js";
import ResetPassword from "./components/LoginPage/ResetPassword.js";

import ProductCategories from "./pages/Category/products/Categories.js";
import ServiceCategories from "./pages/Category/services/Categories.js";

import ProductList from './pages/Products/Product List/ProductList.js'
import ProductListEditPage from "./pages/Products/Product List/ProductListEditPage.js";

import BulkUploadpage from "./pages/Products/bulkUpload.js"


import Sidebar from "./components/SideBar/Sidebar.jsx";
import Dashboard from "./pages/DashBaord/Dashboard.jsx";

import StoreList from './pages/Stores/Lists.js'
import StoreDetails from './pages/Stores/ManageSlots.js';

import About from "./pages/About.jsx";
import Notifications from "./pages/Notification/List.js";
import Profile from "./pages/BottomProfile/Profile.js";
import Inventory from "./pages/Inventory/List.js";
import RequestInventory from "./pages/Inventory/Request/List.js";

import Promtotions from "./pages/Promotions/Promotions.js";
import PromotionsEditPage from "./pages/Promotions/PromotionsEditPage.js";
import RatingAndReview from "./pages/Rating And Review/RatingAndReview.js";
import RatingAndReviewEditPage from "./pages/Rating And Review/RatingAndReviewEditPage.js";
import Message from "./pages/Message/Message.js";
import Support from "./pages/Support/Support.js";
import OrderList from "./pages/Orders/List.js";
import OrdersDetails from "./pages/Orders/OrdersDetails/OrdersDetails.js";
import StoreDetailsForm from "./pages/BottomProfile/StoreDetailsForm.js";
import Setiting from "./pages/SettingPage/Setiting.js";

// import BookingList from "./pages/Booking/Grooming/List.js";
// import BookingDetails from "./pages/Booking/BookingDetails/BookingDetails.js";

import GroomingBookingList from "./pages/Booking/Grooming/List.js";
import GroomingBookingDetails from "./pages/Booking/Grooming/BookingDetails/BookingDetails.js";

import VeterinaryBookingList from "./pages/Booking/Veterinary/List.js";
import VeterinaryBookingDetails from "./pages/Booking/Veterinary/BookingDetails/BookingDetails.js";

import TrainingBookingList from "./pages/Booking/Training/List.js";
import TrainingBookingDetails from "./pages/Booking/Training/BookingDetails/BookingDetails.js";

import BoardingBookingList from "./pages/Booking/Boarding/List.js";
import BoardingBookingDetails from "./pages/Booking/Boarding/BookingDetails/BookingDetails.js";

import DayCareBookingList from "./pages/Booking/DayCare/List.js";
import DayCareBookingDetails from "./pages/Booking/DayCare/BookingDetails/BookingDetails.js";

import Services from "./pages/ServicesPage/List.js";
import DogAdoptionService from "./pages/ServicesPage/dogAdoption/adoptionList.js";
import ServicesRequestList from "./pages/ServicesPage/RequestList.js";
import EnquiryList from "./pages/Enquiry/EnquiryList.js";
import EnquiryDetails from "./pages/Enquiry/EnquiryDetails/EnquiryDetails.js";
import ListingList from "./pages/Listing/ListingList.js";
import SignUp from "./components/SignUp/SignUp.js";

// import Payments from "./pages/Payments/Payments.js";
// import PaymentsEditPage from "./pages/Payments/PaymentsEditpage.js";

import ShopPayments from './pages/Payments/Shop/List.js'
import GroomingPayments from "./pages/Payments/Grooming/List.js"; 
import BoardingPayments from "./pages/Payments/Boarding/List.js";
import VeterinaryPayments from "./pages/Payments/Veterinary/List.js";
import TrainingPayments from "./pages/Payments/Training/List.js";
import DayCarePayments from "./pages/Payments/DayCare/List.js";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getIp = async () => {
    await fetchIpAddress();
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      await getIp();
      if (!["/", "/login", "/reset-password","/sign-up"].includes(location?.pathname)) {
        if (!sessionStorage.getItem('TOKEN')) {
          navigate('/');
        }
      }
    };

    checkAuthentication();
  }, [location, navigate]);


  //////////////////// notification sound //////////////////////
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        if (event.data && event.data.type === 'PLAY_NOTIFICATION_SOUND') {
          playNotificationSound();
        }
      });
    }
  }, []);

  const playNotificationSound = () => {
      const audio = new Audio('/Ingame.mp3');
      audio.play();
  };
  //////////////////// notification sound //////////////////////

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignUp/>} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route path="/dashboard" element={ <Sidebar> <Dashboard /> </Sidebar> } />

        <Route path="/store/list" element={ <Sidebar> <StoreList /> </Sidebar> } />
        <Route path="/store/details" element={ <Sidebar> <StoreDetails /> </Sidebar> } />
        
        <Route path="/categories/products/list" element={ <Sidebar> <ProductCategories /> </Sidebar> } />
        <Route path="/categories/services/list" element={ <Sidebar> <ServiceCategories /> </Sidebar> } />

	      <Route path="/product/list" element={ <Sidebar> <ProductList /> </Sidebar> } />
        <Route path="/product/list/edit" element={ <Sidebar> <ProductListEditPage /> </Sidebar> } />

        <Route path="/bulk-upload" element={ <BulkUploadpage/> } />

        <Route path="/orders/list" element={ <Sidebar> <OrderList /> </Sidebar> } />
        <Route path="/order/details" element={ <Sidebar> <OrdersDetails /> </Sidebar> } />

        <Route path="/about" element={ <Sidebar> <About /> </Sidebar> } />

        {/* <Route path="/payments" element={<Sidebar><Payments /></Sidebar>} /> */}

        <Route path="/payments/shop/list" element={<Sidebar><ShopPayments /></Sidebar>} />
        <Route path="/payments/grooming/list" element={ <Sidebar> <GroomingPayments/> </Sidebar> } />
        <Route path="/payments/boarding/list" element={ <Sidebar> <BoardingPayments /> </Sidebar> } />
        <Route path="/payments/veterinary/list" element={ <Sidebar> <VeterinaryPayments /> </Sidebar> } />
        <Route path="/payments/training/list" element={ <Sidebar> <TrainingPayments /> </Sidebar> } />
        <Route path="/payments/day-care/list" element={ <Sidebar> <DayCarePayments /> </Sidebar> } />

        <Route
          path="/profile"
          element={
            <Sidebar>
              <Profile />
            </Sidebar>
          }
        />

        <Route path="/inventory/list" element={ <Sidebar> <Inventory /> </Sidebar> } />
        <Route path="/inventory/request-list" element={ <Sidebar> <RequestInventory /> </Sidebar> } />

        <Route path="/services/list" element={ <Sidebar> <Services /> </Sidebar> } />
        <Route path="/services/request-list" element={ <Sidebar> <ServicesRequestList /> </Sidebar> } />
        <Route path="/services/adoption" element={ <Sidebar> <DogAdoptionService /> </Sidebar> } />
        
        {/* <Route
          path="/paymentsEditPage"
          element={
            <Sidebar>
              <PaymentsEditPage />
            </Sidebar>
          }
        /> */}
        <Route
          path="/promtotions"
          element={
            <Sidebar>
              <Promtotions />
            </Sidebar>
          }
        />
        <Route
          path="/promotionsEditPage"
          element={
            <Sidebar>
              <PromotionsEditPage />
            </Sidebar>
          }
        />
        {/* <Route
          path="/ratingAndReview"
          element={
            <Sidebar>
              <RatingAndReview />
            </Sidebar>
          }
        /> */}
        <Route
          path="/ratingAndReviewEditPage"
          element={
            <Sidebar>
              <RatingAndReviewEditPage />
            </Sidebar>
          }
        />
        <Route
          path="/message"
          element={
            <Sidebar>
              <Message />
            </Sidebar>
          }
        />
        <Route
          path="/support"
          element={
            <Sidebar>
              <Support />
            </Sidebar>
          }
        />
        
        <Route
          path="/storeDetailsForm"
          element={
            <Sidebar>
              <StoreDetailsForm />
            </Sidebar>
          }
        />
        <Route
          path="/notifications"
          element={
            <Sidebar>
              <Notifications />
            </Sidebar>
          }
        />
        <Route path="/setiting" element={<Sidebar><Setiting /></Sidebar>} />
        {/* Booking Routes */}
        {/* Grooming  */}
        <Route path="/bookings/grooming/list" element={ <Sidebar> <GroomingBookingList /> </Sidebar> } />
        <Route path="/bookings/grooming/detail" element={ <Sidebar> <GroomingBookingDetails /> </Sidebar> } />
        {/* Veterinary  */}
        <Route path="/bookings/veterinary/list" element={ <Sidebar> <VeterinaryBookingList /> </Sidebar> } />
        <Route path="/bookings/veterinary/detail" element={ <Sidebar> <VeterinaryBookingDetails /> </Sidebar> } />
        {/* Training  */}
        <Route path="/bookings/training/list" element={ <Sidebar> <TrainingBookingList /> </Sidebar> } />
        <Route path="/bookings/training/detail" element={ <Sidebar> <TrainingBookingDetails /> </Sidebar> } />
        {/* Boarding  */}
        <Route path="/bookings/boarding/list" element={ <Sidebar> <BoardingBookingList /> </Sidebar> } />
        <Route path="/bookings/boarding/detail" element={ <Sidebar> <BoardingBookingDetails /> </Sidebar> } />

        {/* Day Care  */}
        <Route path="/bookings/day-care/list" element={ <Sidebar> <DayCareBookingList /> </Sidebar> } />
        <Route path="/bookings/day-care/detail" element={ <Sidebar> <DayCareBookingDetails /> </Sidebar> } />

        {/* End */}
        {/* <Route
          path="/bookings/details"
          element={
            <Sidebar>
              <BookingDetails />
            </Sidebar>
          }
        /> */}
        
        <Route
          path="/enquiryList"
          element={
            <Sidebar>
              <EnquiryList />
            </Sidebar>
          }
        />
        <Route
          path="/enquiryDetails"
          element={
            <Sidebar>
              <EnquiryDetails />
            </Sidebar>
          }
        />
        <Route
          path="/listingList"
          element={
            <Sidebar>
              <ListingList />
            </Sidebar>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
